//import defer from './lib/defer'
//import GAuth from 'vue-google-oauth2'

import $store from '@/store';

const gauthOption = {
  clientId: '939724038002-o0bq65t5gi97f83di5bn7psn4lr0tnh9.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account',
};

import { createApp } from 'vue';
import './tailwind.css';
import App from './App.vue';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import router from './router.js';
import VueChatScroll from './lib/vcs';
import store from './store';
import client from 'socket.io-client';
import axios from './lib/myAxios';
import VueAxios from 'vue-axios';
import { createPinia } from 'pinia';
import VueResizeObserver from 'vue-resize-observer';
import VueNativeSock from 'vue-native-websocket-vue3';
import FloatingVue from 'floating-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import VueMeta from 'vue-meta';
import { createGtm } from '@gtm-support/vue-gtm';

import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'floating-vue/dist/style.css';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'swiper/css';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueLazyload from 'vue-lazyload';
import VueNumber from 'vue-number-animation';
import { TailwindPagination } from 'laravel-vue-pagination';
import * as Sentry from '@sentry/vue';
import { setGlobalFp } from '@/lib/utils';
import { sentryConfig } from '@/lib/utils';

const app = createApp(App);

Sentry.init({ app, ...sentryConfig });

setTimeout(() => {
  Sentry.setUser({
    email: store.getters.me.email,
    id: store.getters.me.id,
    username: store.getters.me.username,
  });
}, 2000);

app.use(createPinia());
app.use(store);
app.component('Datepicker', Datepicker);
app.component('TailwindPagination', TailwindPagination);
app.use(FloatingVue);
app.use(VueNumber);
// socket io
const warHost = import.meta.env.VITE_WAR_HOST;
/*window.socket = client(warHost, {
  transports: ["websocket", "polling"],
  extraHeaders: {
    Authorization: `Bearer ${
      store.getters.authToken ? store.getters.authToken : "Test"
    }`,
  },
  query: {
    token: `Bearer ${
      store.getters.authToken ? store.getters.authToken : "Test"
    }`,
  },
  withCredentials: true,
});*/

app.directive('focus', {
  inserted: function (el) {
    el.focus();
  },
});
app.directive('resize', {
  VueResizeObserver,
});
import { rebuildEcho } from './lib/echo';
// store.dispatch("fetchLandingpage");
if (store.getters.isLoggedIn) {
  //store.dispatch('fetchRaces');
  store.dispatch('fetchUser').then((user) => {
    //rebuildEcho()
    //deferUntilAuth.resolve(user)
  }).catch((data) => {
    console.log('issue while fetching user');
  });

  store.dispatch('loadCategoryOptions').then(() => {
  }).catch((data) => {
    console.log('issue while getting category routes');
  });

} else {
  let unregister = store.watch(store._wrappedGetters.isLoggedIn, (newAuth, oldAuth) => {
    if (newAuth) {
      store.dispatch('fetchUser').then((user) => {
        //deferUntilAuth.resolve(user)
        unregister();
      }).catch((data) => {
        console.log('issue while fetching user');
      });
    }
  });
}

app.use(VueAxios, axios);
//app.use(GAuth, gauthOption)
app.use(VueChatScroll);
/*app.use(VueNativeSock, import.meta.env.VITE_CRASH_HOST, {
  protocol: "crash-protocol",
  format: "json",
  reconnection: true,
});*/
app.use(createPinia());
app.use(router);
app.use(PerfectScrollbar);
app.use(VueResizeObserver);
app.use(VueLazyload);
if(import.meta.env.VITE_NODE_ENV == 'production')
{
  app.use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID,
      vueRouter: router,
    }),
  );
}

/*app.use(VueMeta)*/

const sweetalert2Options = {
  //confirmButtonColor: "#F02665",
  //cancelButtonColor: "#12122E",
  background: '#0A0A23',
  color: '#ffffff',
  confirmButton:
    '!bg-onprimary !text-black !hover:bg-onprimary/60 !rounded-md  !mt-4 !mr-4',
  cancelButton: '!rounded-md !mt-4 !ml-4',
  customClass: {
    confirmButton: 'bg-accent text-white',
  },
};
app.use(VueSweetalert2, sweetalert2Options);
app.use(FloatingVue);
app.use(VueToast, { position: 'top-left' });
app.mount('#app');

setGlobalFp();
