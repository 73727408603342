<template>
    <div class="vip-ranking">
        <SectionTitle title="Chanced VIP Ranking System" />
        <CardGroup :jsondata ="all"/>
        <CardGroup :jsondata ="platinum" title="Platinum"/>
        <CardGroup :jsondata ="goat" title="GOAT"/>
        <CardGroup :jsondata ="mvp" title="MVP"/>
    </div>
</template>

<script>
import SectionTitle from './SectionTitle.vue';
import CardGroup from './CardGroup.vue';
import { all , platinum , goat , mvp } from './all'
export default {
  name: 'VipRanking',
  components: {
    SectionTitle,
    CardGroup,
  },
  data(){
    return{
        all,
        platinum,
        goat,
        mvp
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width:992px){
  .vip-ranking {
    margin-right:-2.5rem;
    .section-title{
      text-align: left;
    }
  }
}
</style>