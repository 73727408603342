const GamePlayAmountTitle = "Game play amount"
export const all = [
    {
        "id" : 1,
        "title" : "Copper",
        "style" : "copper",
        "GamePlayAmount" : "10k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 2,
        "title" : "Bronze",
        "style" : "bronze",
        "GamePlayAmount" : "10k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 3,
        "title" : "Silver",
        "style" : "silver",
        "GamePlayAmount" : "100k - 250k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 4,
        "title" : "Gold",
        "style" : "gold",
        "GamePlayAmount" : "100k - 250k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]     
    }
];
export const platinum = [
    {
        "id" : 1,
        "title" : "Platinum I",
        "style" : "platinum",
        "GamePlayAmount" : "10k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 2,
        "title" : "Platinum II",
        "style" : "platinum",
        "GamePlayAmount" : "250k - 500k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 3,
        "title" : "Platinum III",
        "style" : "platinum",
        "GamePlayAmount" : "50k - 100k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 4,
        "title" : "Platinum IV",
        "style" : "platinum",
        "GamePlayAmount" : "100k - 250k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 5,
        "title" : "Platinum V",
        "style" : "platinum",
        "GamePlayAmount" : "250k - 500k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]      
    },
    {
        "id" : 6,
        "title" : "Platinum VI",
        "style" : "platinum",
        "GamePlayAmount" : "10k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]      
    },
    
]
export const goat = [
    {
        "id" : 1,
        "title" : "GOAT I",
        "style" : "goat",
        "GamePlayAmount" : "10k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 2,
        "title" : "GOAT II",
        "style" : "goat",
        "GamePlayAmount" : "50k - 100k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 3,
        "title" : "GOAT III",
        "style" : "goat",
        "GamePlayAmount" : "100k - 250k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 4,
        "title" : "GOAT IV",
        "style" : "goat",
        "GamePlayAmount" : "250k - 500k",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
    {
        "id" : 5,
        "title" : "GOAT V",
        "style" : "goat",
        "GamePlayAmount" : "500k - 1M",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    }
]
export const mvp = [
    {
        "id" : 1,
        "title" : "MVP",
        "style" : "mvp",
        "GamePlayAmount" : "500k - 1M",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus","Monthly Bonus"]       
    },
]