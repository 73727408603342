<template>
  <select-fiat v-if="balS.showSelectFiatModal" />
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="flex-inline flex items-center min-[576px]:rounded-xl max-[575px]:rounded-l-lg bg-secondary-light px-2 py-3 font-semibold md:h-12 md:px-4 md:py-2 max-[480px]:py-1 max-[480px]:h-[36px] h-[40px]">
        <div
          class="flex flex-row items-center space-x-2 text-slate-300 max-[400px]:overflow-hidden">
          <span
            class="flex flex-row text-sm md:text-lg max-[480px]:text-[clamp(0.75rem,1.25vw_+_0.5rem,0.875rem)]">
            {{ currentBalance }}
          </span>
          <img
            alt="icon"
            :src="getIcon(balS.mainCurrency)"
            class="ml-2 h-5 w-5 max-[575px]:!ml-1 max-[575px]:w-4 max-[575px]:h-4 max-[400px]:w-3 max-[400px]:h-3" />
          <span
            class="h-full w-[1px] text-text-dim-color brightness-90 max-[400px]:hidden"
            >|</span
          >
          <img
            alt="icon"
            src="../../assets/svg/chevron.svg"
            class="mx-2 max-[575px]:w-3 max-[400px]:!ml-1 max-[400px]:!mr-0 max-[400px]:w-[0.8rem]" />
        </div>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0">
      <MenuItems
        class="absolute mt-2 w-48 divide-y divide-gray-100 rounded-md bg-tertiary bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="px-1 py-1">
          <MenuItem
            v-slot="{ active }"
            class="rounded px-4 py-3 hover:bg-slate-200"
            as="div"
            v-on:click="switchCurrencies('SC')">
            <div
              :class="[active ? 'opacity-75' : '']"
              class="grid cursor-pointer grid-cols-5">
              <div
                class="col-span-2 flex w-10 flex-shrink-0 items-center justify-start text-white">
                <p class="text-sm font-bold text-slate-900">
                  {{ balS.formatCrypto(balS.cashBalance, true) }}
                </p>
              </div>
              <div class="col-span-3 flex flex-row justify-end space-x-2">
                <img class="h-5 w-5" :src="getIcon('SC')" />
                <p class="text-sm font-bold text-slate-500">SC</p>
              </div>
            </div>
          </MenuItem>
          <MenuItem
            v-slot="{ active }"
            :class="[active ? 'opacity-75' : '']"
            class="rounded px-4 py-3 hover:bg-slate-200"
            as="div"
            v-on:click="switchCurrencies('GC')">
            <div class="grid cursor-pointer grid-cols-5">
              <div
                class="col-span-2 flex w-10 flex-shrink-0 items-center justify-start text-white">
                <p class="text-sm font-bold text-slate-900">
                  {{ balS.formatCrypto(balS.goldBalance, true) }}
                </p>
              </div>
              <div class="col-span-3 flex flex-row justify-end space-x-2">
                <img class="h-5 w-5" :src="getIcon('GC')" />
                <p class="text-sm font-bold text-slate-500">GC</p>
              </div>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
  <Popover v-slot="{ open }" class="relative hidden max-h-full">
    <PopoverButton
      :class="open ? '' : 'text-opacity-90'"
      class="flex-inline flex items-center rounded-xl bg-secondary-light px-2 py-2 font-semibold transition delay-0 duration-150 ease-in-out hover:scale-105 md:h-12 md:px-4 md:py-3">
      <div
        class="flex flex-row items-center space-x-2 max-[575px]:space-x-1 text-slate-300">
        <span class="flex flex-row text-sm md:text-lg">
          {{ currentBalance }}
        </span>
        <img
          alt="icon"
          :src="getIcon(balS.mainBalance)"
          class="ml-2 h-5 w-5 max-[480px]:!ml-1 max-[480px]:w-4 max-[480px]:h-4" />
        <span class="h-full w-[1px] text-text-dim-color brightness-90 max-[400px]:hidden"
          >|</span
        >
        <img alt="icon" src="../../assets/svg/chevron.svg" class="mx-2" />
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0">
      <PopoverPanel
        class="absolute left-1/2 z-50 mt-3 min-w-[350px] max-w-[350px] -translate-x-1/2 transform sm:px-0 md:px-4">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <perfect-scrollbar v-if="!balS.loading" class="rounded-md">
            <div class="relative max-h-[475px] overflow-y-auto bg-slate-100 py-4">
              <PopoverButton
                v-on:click="switchCurrencies('SC')"
                class="-pdl-8 grid w-full cursor-pointer grid-cols-2 rounded-lg px-6 py-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                <div
                  class="flex w-10 flex-shrink-0 items-center justify-start text-white">
                  <p class="text font-bold text-slate-900">
                    {{ balS.formatCrypto(balS.cashBalance, true) }}
                  </p>
                </div>
                <div class="ml-2 flex flex-row justify-start gap-1">
                  <img class="h-6 w-6" :src="getIcon('SC')" />
                  <p class="text-sm font-bold text-slate-500">Sweeps Coins</p>
                </div>
              </PopoverButton>
              <PopoverButton
                v-on:click="switchCurrencies('GC')"
                class="-pdl-8 grid w-full cursor-pointer grid-cols-2 rounded-lg px-6 py-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                <div
                  class="flex w-10 flex-shrink-0 items-center justify-start text-white">
                  <p class="text-sm font-bold text-slate-900">
                    {{ balS.formatCrypto(balS.goldBalance, true) }}
                  </p>
                </div>
                <div class="flex flex-row justify-start space-x-2">
                  <img class="h-5 w-5" :src="getIcon('GC')" />
                  <p class="text-sm font-bold text-slate-500">Gold Coins</p>
                </div>
              </PopoverButton>
            </div>
          </perfect-scrollbar>
          <div
            v-else
            class="min-h-40 flex flex-row items-center justify-center rounded-md bg-slate-100 p-8">
            <svg
              class="text-rh-500 -ml-1 mr-3 h-10 w-10 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
  PopoverOverlay,
} from '@headlessui/vue';
import { getBalanceIcon } from '@/lib/icons';
import { formatCrypto, formatCryptoRaw } from '@/lib/utils';
import { balanceStore } from '@/store/pinia/balanceStore';
import SelectFiat from '@/components/Bank/SelectFiat.vue';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';

export default {
  name: 'Balance',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
    SelectFiat,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  setup() {
    const balS = balanceStore();
    balS.init();
    return { balS };
  },
  computed: {
    currentBalance() {
      return this.balS.formatCryptoRaw(this.balS.mainBalance, true);
    },
  },
  methods: {
    async switchCurrencies(currency) {
      try {
        await this.balS.updateMainCurrency(currency);
        location.reload();
      } catch (e) {}
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    formatAmount(amount, currency) {
      currency = this.balS.displayInFiat ? 'USD' : currency;
      return formatCrypto(amount, this.balS.displayInFiat);

      // const prefix = this.balS.displayInFiat ? "$" : "";
      // const decimalPlaces = this.balS.displayInFiat ? 2 : 8;
      // if (amount === 0) return prefix + amount.toFixed(decimalPlaces);
      // const formated = amount.toFixed(decimalPlaces);
      // return (
      //   prefix +
      //   (this.balS.displayInFiat
      //     ? parseFloat(formated).toLocaleString()
      //     : formated)
      // );
    },
  },
  watch: {
    'balS.displayInFiat': {
      handler: function (selected, old) {
        if (!old && selected) {
          this.balS.showSelectFiatModal = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
