<template>
  <div class="w-screen shadow-lg z-50 flex flex-col justify-end">
    <ul class="flex flex-column items-stretch   sm:px-6 items-center bg-secondary-light">
      <li class="flex-grow">
        <router-link
          v-slot="{ isActive }"
          v-on:click="toggleMenu()"
          :to="!isMenuButton ? '/side-menu' : '/'">
          <div
            :class="
              isMenuButton
                ? 'text-rh-500 border-[#3579F6]'
                : 'text-slate-300 border-transparent'
            "
            class="flex flex-col items-center py-2      text-sm max-[480px]:py-[6px] font-medium text-center rounded-t-lg border-b-2 hover:text-rh-500 hover:border-rh-500 group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              class="max-[480px]:w-[20px] max-[480px]:h-[20px]"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd" />
            </svg>
            <span class="pt-1 max-[480px]:pt-0">Menu</span>
          </div>
        </router-link>
      </li>

      <li class="flex-grow">
        <router-link
          v-slot="{ isActive }"
          :to="!isSearchButton ? '/search' : '/'"
          v-on:click="toggleSearch()">
          <div
            :class="
              isSearchButton
                ? 'text-rh-500 border-[#3579F6]'
                : 'text-slate-300 border-transparent'
            "
            class="flex flex-col items-center py-2   text-sm max-[480px]:py-[6px] font-medium text-center rounded-t-lg border-b-2 hover:text-rh-500 hover:border-rh-500 group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              class="max-[480px]:w-[20px] max-[480px]:h-[20px]"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd" />
            </svg>
            <span class="pt-1 max-[480px]:pt-0">Search</span>
          </div>
        </router-link>
      </li>
    <li class="self-stretch flex-grow">
       <div
           
          class="flex scale-110 translate-y-[-4px] items-center justify-center max-[575px]:gap-x-0 gap-x-4 h-[100%]">
         
          <button
            class="flex-inline flex  h-[100%]   flex-row items-center justify-center gap-2   rounded-t-lg bg-accent font-semibold text-white/90 hover:opacity-70 md:h-12 md:w-auto md:px-5 md:py-3 bg-prim-highlight shadow-btn shadow-accent px-4 max-[767px]:text-sm max-[575px]:w-[clamp(2.125rem, 2.353vw + 1.654rem, 2.5rem)]"
            v-on:click="$router.push({ path: $route.path, query: { tab: 'bank' } })">
            <img
              alt="icon"
              class="h-6 md:h-8 max-[480px]:h-[clamp(1.25rem,3.75vw_+_0.5rem,1.625rem)]"
              src="../assets/3d/wallet.png" />
            <span > Wallet</span>
          </button>
        </div>
      
    </li>
      <li  class="flex-grow">
        <router-link v-slot="{ isActive }" to="/" v-on:click="toggleHome()">
          <div
            :class="
              isHomeButton
                ? 'text-rh-500 border-[#3579F6]'
                : 'text-slate-300 border-transparent'
            "
            class="flex flex-col items-center py-2    text-sm max-[480px]:py-[6px] font-medium text-center rounded-t-lg border-b-2 hover:text-rh-500 hover:border-rh-500 group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              class="max-[480px]:w-[20px] max-[480px]:h-[20px]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            <span class="pt-1 max-[480px]:pt-0">Home</span>
          </div>
        </router-link>
      </li>
     <li class="flex-grow" v-if="!sports_enabled">
        <router-link
          v-slot="{ isActive }"
          v-on:click="toggleBets()"
          :to="!isBetsButton ? '/bets' : '/'">
          <div
            :class="
              isBetsButton
                ? 'text-rh-500 border-[#3579F6]'
                : 'text-slate-300 border-transparent'
            "
            class="flex flex-col items-center py-2    text-sm max-[480px]:py-[6px] font-medium text-center rounded-t-lg border-b-2 hover:text-rh-500 hover:border-rh-500 group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              class="max-[480px]:w-[20px] max-[480px]:h-[20px]"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
                clip-rule="evenodd" /></svg
            ><span class="pt-1 max-[480px]:pt-0">Games</span>
          </div>
        </router-link>
      </li>  

      <li class="flex-grow highlighted-sports" v-if="sports_enabled">
        <a
         
          href="http://juked.co/" target="_blank">
          <span
            
            class="flex flex-col items-center py-2    text-sm max-[480px]:py-[6px] font-medium text-center rounded-t-lg   group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              class="max-[480px]:w-[20px] max-[480px]:h-[20px]"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
                clip-rule="evenodd" /></svg
            ><span class="pt-1 max-[480px]:pt-0">Sports</span>
          </span>
        </a>
      </li>
      <!-- <li class="">
                <router-link v-slot="{ isActive }" v-on:click="toggleChat()" :to="!isChatButton ? '/chat' : '/'">
                    <div :class="isChatButton ? 'text-rh-500 border-[#3579F6]' : 'text-slate-300 border-transparent'"
                        class="flex flex-col items-center py-2 px-5 text-sm max-[480px]:py-[6px] font-medium text-center rounded-t-lg border-b-2 hover:text-rh-500 hover:border-rh-500 group">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                        </svg> <span class="pt-1 max-[480px]:pt-0">Chat</span>
                    </div>
                </router-link>
            </li> -->
    </ul>
  </div>
</template>

<script>
import { generalStore } from '@/store/pinia/generalStore';
import { userStore } from '@/store/userStore';
export default {
  name: 'MobileNavigator',

  setup() {
    const generalS = generalStore;
    return {
      generalS,
    };
  },

  data() {
    console.log(import.meta.env.VITE_ENABLE_SPORTS_BUTTON);
    return {
      isHomeButton: true,
      isChatButton: false,
      isMenuButton: false,
      isSearchButton: false,
      isBetsButton: false,
      userS: userStore,
      sports_enabled : (import.meta.env.VITE_ENABLE_SPORTS_BUTTON == 'true')?true:false
    };
  },
  methods: {
    toggleChat() {
      this.isChatButton = !this.isChatButton;
      this.isHomeButton = false;
      this.isMenuButton = false;
      this.isSearchButton = false;
      this.isBetsButton = false;
    },
    toggleMenu() {
      this.isMenuButton = !this.isMenuButton;
      this.isHomeButton = false;
      this.isChatButton = false;
      this.isSearchButton = false;
      this.isBetsButton = false;
    },
    toggleHome() {
      this.isHomeButton = true;
      this.isChatButton = false;
      this.isMenuButton = false;
      this.isSearchButton = false;
      this.isBetsButton = false;
    },
    toggleSearch() {
      this.generalS.showSearch = !this.generalS.showSearch;
      this.isSearchButton = !this.isSearchButton;
      this.isHomeButton = false;
      this.isChatButton = false;
      this.isMenuButton = false;
      this.isBetsButton = false;
    },
    toggleBets() {
      this.isBetsButton = !this.isBetsButton;
      this.isHomeButton = false;
      this.isChatButton = false;
      this.isMenuButton = false;
      this.isSearchButton = false;
    },
  },
};
</script>

<style>
.highlighted-sports
{
  border: rgba(240,38,101,0) 0.125em solid;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em rgba(240,38,101,0);
  box-shadow: inset 0 0 0.5em 0 rgba(240,38,101,0), 0 0 0.5em 0 rgba(240,38,101,0);
  animation: animateborder 3s linear infinite;
}

@keyframes animateborder {
  0 {
    border: rgba(240,38,101,0) 0.125em solid;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em rgba(240,38,101,0);
    box-shadow: inset 0 0 0.5em 0 rgba(240,38,101,0), 0 0 0.5em 0 rgba(240,38,101,0);
  }
  50% {
     border: rgba(240,38,101,1) 0.125em solid;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em rgba(240,38,101,1);
    box-shadow: inset 0 0 0.5em 0 rgba(240,38,101,1), 0 0 0.5em 0 rgba(240,38,101,1);
  }
  100% {
      border: rgba(240,38,101,0) 0.125em solid;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em rgba(240,38,101,0);
    box-shadow: inset 0 0 0.5em 0 rgba(240,38,101,0), 0 0 0.5em 0 rgba(240,38,101,0);
  }
}
</style>
