<template>
  <div class="md:px-6 px-2 py-2 max-w-[100%]">
    <div class="flex flex-row items-center justify-between px-2 md:py-4 max-[575px]:px-0">
      <router-link
          :to="'/casino/group/' + category.slug"
          class="flex items-center justify-start gap-2 py-0 md:py-4">
        <img
            class="h-8 sm:h-10 md:h-12"
            alt=""
            :src="getCategoryIcon(category)"
            @error="loadFallbackImage"
            :data-fallback="getDefaultCategoryIcon()"
        />
        <p class="font-semibold min-w-max text-md md:text-xl">{{ category.name }}</p>
      </router-link>
      <div class="flex">
        <button
            v-on:click="scroll(false)"
            type="button"
            class="px-2 py-1 sm:px-4 sm:py-2 text-gray-300 border cursor-pointer border-slate-600 rounded-l-xl hover:opacity-60">
          <img
              src="../../assets/3d/right-arrow.png"
              class="h-5 sm:h-6 md:h-8 -rotate-180" />
        </button>
        <button
            v-on:click="scroll(true)"
            type="button"
            class="px-2 py-1 sm:px-4 sm:py-2 text-gray-300 border cursor-pointer border-slate-600 rounded-r-xl hover:opacity-60">
          <img src="../../assets/3d/right-arrow.png" class="h-5 sm:h-6 md:h-8" />
        </button>
      </div>
    </div>

    <div ref="tiles" class="py-2 overflow-x-scroll no-scrollbar home-casino-slider">
      <div
          v-for="(game, i) in games"
          v-bind:key="i"
          class="slider-tile"
          :data-key="i">
        <router-link
            class="transition duration-200 ease-in-out hover:opacity-80 delay-0 hover:scale-105 hover:rotate-1"
            :to="getGameLink(game, game.provider)"
            :key="i">
          <img
              :src="getTileImage(game)"
              @error="loadFallbackImage"
              :data-fallback="getDefaultTileImage(game)"
              alt="Game Tile"
              v-tooltip="game.name"
              class="flex flex-col items-center transition duration-150 ease-in-out rounded-md shadow-md delay-0 hover:rotate-1 hover:scale-105" />
        </router-link>
      </div>
      <div class="slider-tile see-all-button bg-secondary-light rounded-md">
        <router-link class="flex flex-col items-center justify-center h-full" :to="'/casino/group/' + category.slug">
          <img
              alt="View all"
              :src="getCategoryIcon(category)"
              @error="loadFallbackImage"
              :data-fallback="getDefaultCategoryIcon()"
              class="sm:max-w-[130px] max-w-[80px] w-full transition duration-150 ease-in-out rounded-md delay-0 hover:rotate-1 hover:scale-105" />
          <p class="text-center font-bold italic sm:text-[26px] text-[18px] sm:mt-4 mt-2">SEE All</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCategoryIcon,
  getDefaultCategoryIcon,
  getDefaultTileImage,
  getGameLink,
  getTileImage,
  loadFallbackImage,
} from '@/utils';
export default {
  name: 'HomeCategorySlider',
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      games: this.category?.games ?? [],
      scrollAmount: 0,
      currentIndex: 0
    };
  },
  methods: {
    getGameLink,
    getDefaultTileImage,
    getTileImage,
    getCategoryIcon,
    getDefaultCategoryIcon,
    loadFallbackImage,
    scroll(isRight = true) {
      this.setFade(isRight);
      const menu = this.$refs.tiles;

      const slide_width = menu.getElementsByClassName('slider-tile')[0].clientWidth;
      menu.scrollTo({
        left: this.scrollAmount,
        behavior: 'smooth',
      });
    },
    setFade(isRight = true) {
      const menu = this.$refs.tiles;

      if (menu) {
        const width = menu.scrollWidth - menu.clientWidth;
        const total_part = Math.ceil(menu.scrollWidth / menu.clientWidth);
        const all_slide = menu.getElementsByClassName('slider-tile');
        for (const slide of all_slide) {
          slide.classList.remove('fade');
        }
        const slide_width = menu.getElementsByClassName('slider-tile')[0].clientWidth;
        const visible_item = Math.ceil(menu.clientWidth / slide_width);

        this.currentIndex = isRight
            ? this.currentIndex + visible_item
            : this.currentIndex - visible_item;

        var next_visible_index = this.currentIndex;

        if (this.currentIndex <= visible_item) {
          next_visible_index = visible_item;
        }

        const all_faded = menu.querySelectorAll(
            '.slider-tile:nth-child(n+' + next_visible_index + ')',
        );

        for (const fade of all_faded) {
          fade.classList.add('fade');
        }

        const next_visible_item =
            menu.getElementsByClassName('slider-tile')[next_visible_index - 1];

        if (next_visible_item) {
          this.scrollAmount = next_visible_item.offsetLeft - menu.offsetLeft;
        }

        if (!isRight && this.currentIndex <= 0) {
          this.scrollAmount = 0;
          this.currentIndex = 0;
        }
        if (this.currentIndex >= total_part) {
          this.currentIndex = total_part;
        }
      }
    },
  },
};
</script>