export const ClubBanefitData = [
    {
        "id":1,
        "title": "Boost",
        "desc" : "Every week and every month, expect a fresh bonus based on your recent games. The more you play, the higher the bonuses",
        "img":"img1.webp"
    },
    {
        "id":2,
        "title": "Dedicated VIP Host",
        "desc" : "Receive your own dedicated VIP host who will support and cater to your betting needs",
        "img":"img2.webp"
    },
    {
        "id":3,
        "title": "Recent Play Bonuses",
        "desc" : "Having a rough streak of luck? Chanced offers money back on losses every time you level up.",
        "img":"img3.webp"
    },
    {
        "id":4,
        "title": "Level-Ups",
        "desc" : "Reach a new level and get paid. The level-ups get better the higher you go",
        "img":"img4.webp"
    },
    {
        "id":5,
        "title": "Bespoke",
        "desc" : "Work with your dedicated VIP host to tailor benefits to your gaming needs.",
        "img":"img5.webp"
    }
]